<template>
  <v-form
    ref="form"
    lazy-validation
  >
    <v-card>
      <v-card-title v-if="title">
        <span class="text-h3">{{ $t(title) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.name"
                dense
                :disabled="readOnly"
                :label="$t('name_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.staff_type"
                dense
                :disabled="readOnly"
                :items="staffTypeOptions"
                item-value="id"
                item-text="name"
                :label="$t('staff_type_*')"
                :rules="[rules.required]"
              />
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <single-date-picker
                v-model="options.dob"
                :label="$t('date_of_birth_*')"
                :max="options.next_service_date"
                :disabled="readOnly"
                :rules="[rules.required, validateDateOfBirth]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.email"
                dense
                :label="$t('email_*')"
                :rules="[rules.required, rules.email]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                v-model="options.country_code"
                dense
                :items="['+92']"
                item-value="id"
                item-text="name"
                :label="$t('country_code_*')"
                :rules="[rules.required]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.phone_number"
                dense
                type="number"
                :label="$t('phone_number_*')"
                :rules="[
                  rules.required,
                  rules.startFrom(3),
                  rules.min_length(10),
                  rules.max_length(10),
                ]"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="options.cnic"
                dense
                :label="$t('cnic_*')"
                type="number"
                :rules="[
                  rules.required,
                  rules.min_length(13),
                  rules.max_length(13),
                ]"
              />
            </v-col>
            <v-col
              :cols="options?.picture_url ? '6' : '12'"
              :sm="options?.picture_url ? '4' : '6'"
            >
              <v-file-input
                v-model="options.picture_file"
                dense
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                :label="$t('picture')"
                :rules="[rules.file_size]"
                @change="() => delete options.picture_url"
              />
            </v-col>
            <v-col
              v-if="options.picture_url"
              cols="6"
              sm="2"
              class="px-0 py-0"
            >
              <image-modal
                :title="$t('picture')"
                :src="options.picture_url"
              />
            </v-col>
            <v-col
              :cols="options?.cnic_front ? '6' : '12'"
              :sm="options?.cnic_front ? '4' : '6'"
            >
              <v-file-input
                v-model="options.cnic_front_file"
                dense
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                :label="$t('cnic_front')"
                :rules="[rules.file_size]"
                @change="() => delete options.cnic_front"
              />
            </v-col>
            <v-col
              v-if="options.cnic_front"
              cols="6"
              sm="2"
              class="px-0 py-0"
            >
              <image-modal
                :title="$t('cnic_front')"
                :src="options.cnic_front"
              />
            </v-col>
            <v-col
              :cols="options?.cnic_back ? '6' : '12'"
              :sm="options?.cnic_back ? '4' : '6'"
            >
              <v-file-input
                v-model="options.cnic_back_file"
                dense
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                :label="$t('cnic_back')"
                :rules="[rules.file_size]"
                @change="() => delete options.cnic_back"
              />
            </v-col>
            <v-col
              v-if="options.cnic_back"
              cols="6"
              sm="2"
              class="px-0 py-0"
            >
              <image-modal
                :title="$t('cnic_back')"
                :src="options.cnic_back"
              />
            </v-col>

            <v-col
              :cols="options?.driving_license_front ? '6' : '12'"
              :sm="options?.driving_license_front ? '4' : '6'"
            >
              <v-file-input
                v-model="options.driving_license_front_file"
                dense
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                :label="$t('driving_license_front')"
                :rules="[rules.file_size]"
                @change="() => delete options.driving_license_front"
              />
            </v-col>
            <v-col
              v-if="options.driving_license_front"
              cols="6"
              sm="2"
              class="px-0 py-0"
            >
              <image-modal
                :title="$t('driving_license_front')"
                :src="options.driving_license_front"
              />
            </v-col>
            <v-col
              :cols="options?.driving_license_back ? '6' : '12'"
              :sm="options?.driving_license_back ? '4' : '6'"
            >
              <v-file-input
                v-model="options.driving_license_back_file"
                dense
                accept="image/png, image/jpeg, image/bmp"
                prepend-icon="mdi-camera"
                :label="$t('driving_license_back')"
                :rules="[rules.file_size]"
                @change="() => delete options.driving_license_back"
              />
            </v-col>
            <v-col
              v-if="options.driving_license_back"
              cols="6"
              sm="2"
              class="px-0 py-0"
            >
              <image-modal
                :title="$t('driving_license_back')"
                :src="options.driving_license_back"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <small v-if="!readOnly">{{ $t("indicates_required_field") }}</small>
        <v-spacer />
        <v-btn
          color="red darken-1"
          text
          @click="closeForm()"
        >
          Close
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          text
          :loading="loading"
          @click="saveItem"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>
<script>
import { validationRules } from "@/mixins/validationRules";
import { mapGetters } from "vuex";

export default {
  components: {
    SingleDatePicker: () => import("@/components/base/SingleDatePicker.vue"),
    ImageModal: () => import("@/components/Modals/ImageModal.vue"),
  },
  mixins: [validationRules],
  props: {
    type: {
      type: String,
      default() {
        return {
          time: null,
        };
      },
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    readOnly: {
      type: Boolean,
      default() {
        return false;
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      options: {},
      //
      staffTypeOptions: [
        {
          id: "chief_crew",
          name: "Chief Crew",
        },
        {
          id: "driver",
          name: "Driver",
        },
      ],
      statusOptions: [
        {
          id: "1",
          name: "active",
        },
        {
          id: "0",
          name: "inactive",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      // tenantLoading: "tenantsManagement/getIsLoading",
      // tenantsList: "tenantsManagement/getTenantOptions",
      //
    }),
    // tenantOEM() {
    //   return this.tenantsList.filter((r) => r.product == "oem");
    // },
    title() {
      if (this.readOnly) {
        return "view";
      }
      if (this.type == "create") {
        return "add_new";
      } else if (this.type == "edit") {
        return "edit";
      } else {
        return "";
      }
    },
  },
  watch: {
    // "options.tenant_id": {
    //   handler: async function (val) {
    //     if (this.type == "create") this.fetchDependents(val);
    //   },
    // },
  },
  async mounted() {
    if (this.type == "create") {
      if (!this.authUser.isAdmin) {
        // this.options.tenant_id = this.authUser.tenant_id;
        // this.fetchDependents(this.authUser.tenant_id);
      }
      // this.options.currency = "PKR";
    }

    if (this.type == "edit") {
      this.options = { ...this.item };
      this.options.country_code = "+92";
      // this.fetchDependents(this.options.tenant_id);
    }
  },
  methods: {
    saveItem() {
      if (this.$refs.form.validate()) {
        this.$emit("onsave", this.options);
      }
    },
    closeForm() {
      this.$refs.form.reset();
      this.$emit("onclose");
    },

    //
  },
};
</script>
<style lang="sass" scoped>
.connectorsRow

  display: flex
  gap:1rem
  .field
    min-width:150px !important
    max-width:165px !important


.deleteButton
  cursor: pointer
  .deleteIcon
    &:hover
      color: red
</style>
